import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  AddGuardBioDataVM,
  GMAGuardRegistration,
  StandaloneGuardRegistrationVM,
} from "src/app/interfaces/employment";
import { Bank, CommonResponse, Lga, State } from "src/app/interfaces/home";
import {
  CreateJobFunctionVM,
  GMAJobFunction,
  UpdateJobFunctionVM,
} from "src/app/interfaces/job-function";
import {
  AddEducationalBackground,
  AddNextOfKin,
  AddPrevEmployer,
  BiometricCaptureAction,
  ChildInfo,
  EducationLevelEnum,
  GenderEnum,
  MaritalStatusEnum,
  NextOfKinRelationshipEnum,
} from "src/app/interfaces/premployment";
import { BankService } from "src/app/services/bank.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { GmaEmploymentService } from "src/app/services/gma-employment.service";
import { JobFunctionService } from "src/app/services/job-function.service";
import { PaystackGatewayService } from "src/app/services/paystack-gateway.service";

@Component({
  selector: "app-gma-guard-registration",
  templateUrl: "./gma-guard-registration.component.html",
  styleUrls: ["./gma-guard-registration.component.scss"],
})
export class GmaGuardRegistrationComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  jobFunctionForm: FormGroup;
  editingJobFunction: boolean;
  fetchingJobFunctions: boolean;
  allJobFunctions: GMAJobFunction[] = [];
  allActiveJobFunctions: GMAJobFunction[] = [];
  selectedJobFunctions: GMAJobFunction[] = [];
  jobFunctionToEdit: GMAJobFunction;
  jobFuncCols: any[];
  jobFunction: GMAJobFunction;
  steps: MenuItem[];
  activeStep: number = 0;
  showGuardRegistration: boolean = true;
  personalForm: FormGroup;
  backgroundForm: FormGroup;
  nextofkinForm: FormGroup;
  educationForm: FormGroup;
  prevEmployerForm: FormGroup;
  medAcctForm: FormGroup;
  additionalForm: FormGroup;
  allStates: State[];
  theState: State;
  theBackgroundState: State;
  theNextOfKinState: State;
  theEmployerState: State;
  passportImageSrc: string;
  uploadedPassport: any[] = [];
  allLgas: Lga[];
  allBackgroundLgas: Lga[];
  allNextOfKinLgas: Lga[];
  allEmployerLgas: Lga[];
  theLga: Lga;
  theNextOfKinLga: Lga;
  theBackgroundLga: Lga;
  theEmployerLga: Lga;
  isInAClubRadioButton: number;
  allGenders: {
    value: string;
    key: number;
  }[];
  theGender: {
    value: string;
    key: number;
  };
  theApplicantGender: {
    value: string;
    key: number;
  };
  allNxtOfKinRelationships: {
    value: string;
    key: number;
  }[];
  theNxtOfKinRelationship: {
    value: string;
    key: number;
  };
  allEducationalLevels: {
    value: string;
    key: number;
  }[];
  theEducationalLevel: {
    value: string;
    key: number;
  };
  maxDate: string = "2000-01-01";
  childMaxDate: string = "2000-01-01";
  uploadedApplicationLetters: any[] = [];
  userIsYetToClickUploadApplicationLetter: boolean;
  allMaritalStatus: {
    value: string;
    key: number;
  }[];
  theMaritalStatus: {
    value: string;
    key: number;
  };
  childrenAddedToList: ChildInfo[];
  selectedChildOnList: ChildInfo;
  allBanks: Bank[];
  thePrimaryBank: Bank;
  theSecondaryBank: Bank;
  childerenCols: any[];
  educBackgroundCols: any[];
  prevEmployerCols: any[];
  nextOfKins: AddNextOfKin[];
  selectedNextOfKin: AddNextOfKin;
  nxtOfKinCols: any[];
  editingNextOfKin: boolean = false;
  nextOfKinToUpdate: AddNextOfKin;
  educationalBackgroundList: AddEducationalBackground[];
  selectedEducationalBackground: AddEducationalBackground;
  editingEducInfo: boolean = false;
  educInfoToUpdate: AddEducationalBackground;
  prevEmployerList: AddPrevEmployer[];
  selectedPrevEmployer: AddPrevEmployer;
  editingPrevEmp: boolean = false;
  prevEmpToUpdate: AddPrevEmployer;
  primaryAcctName: string = "";
  secondaryAcctName: string = "";
  isDrinkingRadioButton: number;
  isSmokingRadioButton: number;
  isGamblingRadioButton: number;
  isHypertensiveRadioButton: number;
  isArthritusRadioButton: number;
  isDiabeticRadioButton: number;
  isSufferingUnknownRadioButton: number;
  isExMilitantRadioButton: number;
  isCombatantRadioButton: number;
  isArrestedRadioButton: number;
  isConvictedRadioButton: number;
  isResignedRadioButton: number;
  hasAgreed: boolean = false;
  hasDeclared: boolean = false;
  isRegistering: boolean;
  allNewlyRegisteredGuards: GMAGuardRegistration[] = [];
  theRegisteredGuard: GMAGuardRegistration;
  bioImgSrc: string = "assets/gma/PlaceFinger.bmp";
  applicantInViewBioData: boolean[];
  leftBioPercentage: number = 0;
  rightBioPercentage: number = 0;
  openCapturePrintsDialogue: boolean;
  allBiometricCaptures: {
    key: number;
    value: string;
  }[];
  theBiometricCapture: {
    key: number;
    value: string;
  };

  constructor(
    private fb: FormBuilder,
    public paystackGatewayService: PaystackGatewayService,
    public bankService: BankService,
    public jobFunctionService: JobFunctionService,
    public employmentService: GmaEmploymentService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public fileStorageService: FileStorageService,
    public messageService: MessageService
  ) {
    this.jobFunctionForm = fb.group({
      Title: ["", Validators.required],
      Alias: ["", Validators.required],
      Function: ["", Validators.required],
    });

    this.personalForm = fb.group({
      Firstname: ["", Validators.required],
      Email: [
        "",
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ],
      Lastname: ["", Validators.required],
      State: ["", Validators.required],
      Othername: ["", Validators.required],
      LGA: ["", Validators.required],
      Nickname: ["", Validators.required],
      Street: ["", Validators.required],
      Mobile: ["", Validators.required],
      Address: [""],
      Gender: ["", Validators.required],
      DOB: ["", Validators.required],
    });

    this.backgroundForm = fb.group({
      StateOfOrigin: ["", Validators.required],
      MaritalStatus: ["", Validators.required],
      LGAOfOrigin: ["", Validators.required],
      MarriageDate: [""],
      Tribe: ["", Validators.required],
      MarriageLocation: [""],
      IsInAClub: ["", Validators.required],
      ClubName: [""],
      SpouseFirstname: [""],
      SpouseLastname: [""],
      SpouseOccupation: [""],
      FatherFullname: ["", Validators.required],
      FatherAddress: ["", Validators.required],
      MotherFullname: ["", Validators.required],
      MotherAddress: ["", Validators.required],
      ChildFirstname: [""],
      ChildLastname: [""],
      ChildGender: [""],
      ChildDOB: [""],
    });

    this.nextofkinForm = fb.group({
      Firstname: ["", Validators.required],
      Lastname: ["", Validators.required],
      State: ["", Validators.required],
      LGA: ["", Validators.required],
      Othername: [""],
      Street: ["", Validators.required],
      Relationship: ["", Validators.required],
      Address: [""],
      Mobile: ["", Validators.required],
      Email: [
        "",
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    });

    this.educationForm = fb.group({
      EducationLevel: ["", Validators.required],
      ProgramStartDate: ["", Validators.required],
      Institution: ["", Validators.required],
      ProgramEndDate: ["", Validators.required],
      QualificationAttained: ["", Validators.required],
      GradeAwarded: ["", Validators.required],
    });

    this.prevEmployerForm = fb.group({
      EmployerName: ["", Validators.required],
      RoleOccupied: ["", Validators.required],
      EmployerState: ["", Validators.required],
      YearOfEmployment: ["", Validators.required],
      EmployerLGA: ["", Validators.required],
      YearOfTermination: ["", Validators.required],
      EmployerStreet: ["", Validators.required],
      ReasonForTermination: ["", Validators.required],
      EmployerAddress: [""],
    });

    this.medAcctForm = fb.group({
      IsDrinking: ["", Validators.required],
      IsSmoking: ["", Validators.required],
      IsGambling: ["", Validators.required],
      IsHypertensive: ["", Validators.required],
      IsArthritis: ["", Validators.required],
      IsDiabetic: ["", Validators.required],
      IsSufferingUnknown: ["", Validators.required],
      UnknownDiseaseDesc: [""],
      BVN: ["", Validators.required],
      PrimaryBank: ["", Validators.required],
      PrimaryAcctNo: ["", Validators.required],
      SecondaryBank: ["", Validators.required],
      SecondaryAcctNo: ["", Validators.required],
      PensionPin: ["", Validators.required],
      PPID: ["", Validators.required],
      TaxIdentificationNo: [""],
      NIN: [""],
    });

    this.additionalForm = fb.group({
      IsMilitary: ["", Validators.required],
      HasCombatTraining: ["", Validators.required],
      HasBeenArrested: ["", Validators.required],
      HasBeenConvicted: ["", Validators.required],
      HasBeenAskedToResign: ["", Validators.required],
      AgreesToTerms: [""],
      Declaration: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Guard Management",
        routerLink: ["/home/guard-management/item-settings"],
      },
      {
        label: "Guard Registration",
        routerLink: ["/home/guard-management/guard-registration"],
      },
    ]);

    this.jobFuncCols = [
      { field: "title", header: "Title" },
      { field: "alias", header: "Alias" },
      { field: "function", header: "Function" },
      { field: "isDeleted", header: "Status" },
    ];

    this.steps = [
      {
        label: "Personal Info",
      },
      {
        label: "Background Info",
      },
      {
        label: "Next Of Kin",
      },
      {
        label: "Education",
      },
      {
        label: "Previous Employer",
      },
      {
        label: "Medical & Account",
      },
      {
        label: "Other Info",
      },
    ];

    this.allGenders = [
      {
        value: "Male",
        key: GenderEnum.Male,
      },
      {
        value: "Female",
        key: GenderEnum.Female,
      },
      {
        value: "Unspecified",
        key: GenderEnum.Unspecified,
      },
    ];

    this.allMaritalStatus = [
      {
        value: "Single",
        key: MaritalStatusEnum.Single,
      },
      {
        value: "Married",
        key: MaritalStatusEnum.Married,
      },
      {
        value: "Divorced",
        key: MaritalStatusEnum.Divorced,
      },
      {
        value: "Widowed",
        key: MaritalStatusEnum.Widowed,
      },
    ];

    this.childerenCols = [
      { field: "firstname", header: "Firstname" },
      { field: "lastname", header: "Lastname" },
      { field: "dateOfBirth", header: "DateOfBirth" },
    ];

    this.nxtOfKinCols = [
      { field: "firstname", header: "Firstname" },
      { field: "lastname", header: "Lastname" },
      { field: "othername", header: "Othername" },
      { field: "street", header: "Street" },
      { field: "mobile", header: "Mobile" },
      { field: "email", header: "Email" },
      { field: "relationshipValue", header: "RelationshipValue" },
    ];

    this.educBackgroundCols = [
      { field: "educationalLevelValue", header: "EducationalLevelValue" },
      { field: "programStartDate", header: "ProgramStartDate" },
      { field: "institution", header: "Institution" },
      { field: "programEndDate", header: "ProgramEndDate" },
      { field: "qualificationAttained", header: "QualificationAttained" },
      { field: "gradeAwarded", header: "GradeAwarded" },
    ];

    this.prevEmployerCols = [
      { field: "name", header: "Name" },
      { field: "role", header: "Role" },
      { field: "stateName", header: "StateName" },
      { field: "lGAName", header: "LGAName" },
      { field: "street", header: "Street" },
      { field: "yearOfEmployment", header: "YearOfEmployment" },
      { field: "yearOfTermination", header: "YearOfTermination" },
      { field: "reasonForTermination", header: "ReasonForTermination" },
    ];

    this.allNxtOfKinRelationships = [
      {
        value: "Mother",
        key: NextOfKinRelationshipEnum.Mother,
      },
      {
        value: "Father",
        key: NextOfKinRelationshipEnum.Father,
      },
      {
        value: "Sister",
        key: NextOfKinRelationshipEnum.Sister,
      },
      {
        value: "Brother",
        key: NextOfKinRelationshipEnum.Brother,
      },
      {
        value: "Son",
        key: NextOfKinRelationshipEnum.Son,
      },
      {
        value: "Daughter",
        key: NextOfKinRelationshipEnum.Daughter,
      },
    ];

    this.allEducationalLevels = [
      {
        value: "Primary",
        key: EducationLevelEnum.Primary,
      },
      {
        value: "Secondary",
        key: EducationLevelEnum.Secondary,
      },
      {
        value: "Tertiary",
        key: EducationLevelEnum.Tertiary,
      },
      {
        value: "PostGraduate",
        key: EducationLevelEnum.PostGraduate,
      },
    ];

    this.applicantInViewBioData = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];

    this.allBiometricCaptures = [
      {
        key: BiometricCaptureAction.Left_Pinky,
        value: "Left Pinky",
      },
      {
        key: BiometricCaptureAction.Left_RingFinger,
        value: "Left Ring Finger",
      },
      {
        key: BiometricCaptureAction.Left_MiddleFinger,
        value: "Left Middle Finger",
      },
      {
        key: BiometricCaptureAction.Left_IndexFinger,
        value: "Left Index Finger",
      },
      {
        key: BiometricCaptureAction.Left_Thumb,
        value: "Left Thumb",
      },
      {
        key: BiometricCaptureAction.Right_Thumb,
        value: "Right Thumb",
      },
      {
        key: BiometricCaptureAction.Right_IndexFinger,
        value: "Right Index Finger",
      },
      {
        key: BiometricCaptureAction.Right_MiddleFinger,
        value: "Right Middle Finger",
      },
      {
        key: BiometricCaptureAction.Right_RingFinger,
        value: "Right Ring Finger",
      },
      {
        key: BiometricCaptureAction.Right_Pinky,
        value: "Right Pinky",
      },
    ];

    this.childrenAddedToList = [];
    this.nextOfKins = [];
    this.educationalBackgroundList = [];
    this.prevEmployerList = [];
    //Set minimum date
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var strMM = mm + "";
    if (mm < 10) strMM = "0" + mm;
    var yyyy = today.getFullYear() - 18;
    this.maxDate = yyyy + "-12-31";
    this.childMaxDate = today.getFullYear() + "-" + strMM + "-" + dd;

    this.FetchAllJobFunctions();
    this.FetchAllStates();
    this.FetchAllBanks();
    this.FetchAllRegisteredGuardInfos();
  }

  async FetchAllStates() {
    this.employmentService.GetAllStates().subscribe(
      async (data) => {
        this.allStates = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all states at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllBanks() {
    this.bankService.allBankData().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allBanks = data.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: error ?? "Some errors occurred",
        });
      }
    );
  }

  async FetchAllJobFunctions() {
    this.fetchingJobFunctions = true;
    this.jobFunctionService.GetAllJobFunctions().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.fetchingJobFunctions = false;
          return;
        }

        this.jobFunction = null;
        this.allJobFunctions = data.responseData;
        this.allActiveJobFunctions = data.responseData.filter(
          (x) => !x.isDeleted
        );
        this.fetchingJobFunctions = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all job functions at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingJobFunctions = false;
      }
    );
  }

  async FetchAllRegisteredGuardInfos() {
    this.employmentService.GetAllRegisteredGuardInformations().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allNewlyRegisteredGuards = [];
        data.responseData.forEach((x) => {
          x.personalInfo.fullname =
            x.personalInfo.lastname +
            " " +
            x.personalInfo.firstname +
            " " +
            x.personalInfo.othername;
          this.allNewlyRegisteredGuards.push(x);
        });
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create job function at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CreateJobFunction() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Creating Job Function...",
    });

    const postData: CreateJobFunctionVM = {
      jobTitle: this.jobFunctionForm.get("Title").value,
      jobAlias: this.jobFunctionForm.get("Alias").value,
      jobFunction: this.jobFunctionForm.get("Function").value,
    };

    this.jobFunctionService.CreateJobFunction(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Job Function Created Successfully!",
        });
        this.CloseEditingJobFunction();
        this.FetchAllJobFunctions();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to create job function at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  CloseEditingJobFunction() {
    this.editingJobFunction = false;
    this.jobFunctionForm.reset();
  }

  UpdateJobFunction() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Updating Job Function...",
    });

    const id = this.jobFunctionToEdit.id;
    const postData: UpdateJobFunctionVM = {
      jobTitle: this.jobFunctionForm.get("Title").value,
      jobAlias: this.jobFunctionForm.get("Alias").value,
      jobFunction: this.jobFunctionForm.get("Function").value,
    };

    this.jobFunctionService.UpdateJobFunction(id, postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Job Function Updated Successfully!",
        });
        this.CloseEditingJobFunction();
        this.FetchAllJobFunctions();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to update job function at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  EditJobFunction(item: GMAJobFunction) {
    this.editingJobFunction = true;
    this.jobFunctionForm.patchValue({
      Title: item.title,
      Alias: item.alias,
      Function: item.function,
    });
    this.jobFunctionToEdit = item;

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  SwitchJobFunctionStatus(item: GMAJobFunction) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Switching Job Function Status...",
    });

    this.jobFunctionService.DeleteJobFunction(item.id).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Job Function Status Switched Successfully!",
        });
        this.FetchAllJobFunctions();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to switch job function status at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  ShowGuardRegistrationGrid() {
    this.activeStep = 0;
    this.showGuardRegistration = true;
  }

  onUploadPassport(event, form) {
    this.uploadedPassport = [];
    for (const file of event.files) {
      this.uploadedPassport.push(file);
    }
    this.passportImageSrc = this.uploadedPassport[0].objectURL;

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Passport Image Uploaded Successfully!",
    });
    form.clear();
  }

  LoadUpStateLGAs() {
    if (this.theState) {
      this.allLgas = this.theState.lgas;
    } else {
      this.allLgas = [];
      this.theLga = null;
    }
  }

  onCancelApplicationLetterUpload() {
    this.uploadedApplicationLetters = [];
  }

  NotifyUserToClickUpload(identifier: number) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });

    if (identifier == 1) {
      this.userIsYetToClickUploadApplicationLetter = true;
    }
  }

  onUploadApplicationLetter(event) {
    this.uploadedApplicationLetters = [];
    for (const file of event.files) {
      this.uploadedApplicationLetters.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Application Letter(s) Uploaded",
    });

    this.userIsYetToClickUploadApplicationLetter = false;
  }

  NextStep() {
    this.activeStep++;
  }

  PreviousStep() {
    this.activeStep--;
  }

  LoadUpBackgroundStateLGAs() {
    if (this.theBackgroundState) {
      this.allBackgroundLgas = this.theBackgroundState.lgas;
    } else {
      this.allBackgroundLgas = [];
      this.theBackgroundLga = null;
    }
  }

  AddChildToList() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Child Info...",
    });

    this.childrenAddedToList.push({
      lastname: this.ChildLastnameValue,
      firstname: this.ChildFirstnameValue,
      gender: this.theGender.key,
      dateOfBirth: this.ChildDOBValue,
    });

    this.backgroundForm.patchValue({
      ChildFirstname: "",
      ChildLastname: "",
      ChildDOB: "",
    });
    this.theGender = null;

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Added Successfully.",
    });
  }

  RemoveChildFromList(item: ChildInfo) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Child Info...",
    });

    this.childrenAddedToList.splice(this.childrenAddedToList.indexOf(item), 1);

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Removed Successfully.",
    });
  }

  LoadUpNextofKinStateLGAs() {
    if (this.theNextOfKinState) {
      this.allNextOfKinLgas = this.theNextOfKinState.lgas;
    } else {
      this.allNextOfKinLgas = [];
      this.theNextOfKinLga = null;
    }
  }

  RemoveNextOfKin(item: AddNextOfKin) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Next Of Kin Info...",
    });

    this.nextOfKins.splice(this.nextOfKins.indexOf(item), 1);

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Removed Successfully.",
    });
  }

  AddNextOfKinToList() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Next Of Kin Info...",
    });

    this.nextOfKins.push({
      firstname: this.nextofkinForm.get("Firstname").value,
      lastname: this.nextofkinForm.get("Lastname").value,
      state: this.theNextOfKinState.id,
      stateEntity: this.theNextOfKinState,
      lGA: this.theNextOfKinLga.id,
      lGAEntity: this.theNextOfKinLga,
      othername: this.nextofkinForm.get("Othername").value,
      street: this.nextofkinForm.get("Street").value,
      relationship: this.theNxtOfKinRelationship.key,
      relationshipValue: this.theNxtOfKinRelationship.value,
      mobile: this.nextofkinForm.get("Mobile").value,
    });
    this.nextofkinForm.reset();
    this.theNxtOfKinRelationship = null;
    this.theNextOfKinState = null;
    this.theNextOfKinLga = null;

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Added Successfully.",
    });
  }

  EditNextOfKin(item: AddNextOfKin) {
    this.editingNextOfKin = true;
    this.nextofkinForm.patchValue({
      Firstname: item.firstname,
      Lastname: item.lastname,
      Othername: item.othername,
      Street: item.street,
      Mobile: item.mobile,
      Email: item.email,
    });

    this.theNextOfKinState = this.allStates.find((x) => x.id == item.state);
    this.allNextOfKinLgas = this.theNextOfKinState.lgas;
    this.theNextOfKinLga = this.allNextOfKinLgas.find((x) => x.id == item.lGA);
    this.theNxtOfKinRelationship = this.allNxtOfKinRelationships.find(
      (x) => x.key == item.relationship
    );
    this.nextOfKinToUpdate = item;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  CloseEditingNextOfKin() {
    this.editingNextOfKin = false;
    this.nextofkinForm.reset();
    this.theNextOfKinLga = null;
    this.theNextOfKinState = null;
    this.theNxtOfKinRelationship = null;
    this.nextOfKinToUpdate = null;
  }

  UpdateNextOfKin() {
    this.nextOfKinToUpdate.firstname =
      this.nextofkinForm.get("Firstname").value;
    this.nextOfKinToUpdate.lastname = this.nextofkinForm.get("Lastname").value;
    this.nextOfKinToUpdate.state = this.theNextOfKinState.id;
    this.nextOfKinToUpdate.stateEntity = this.theNextOfKinState;
    this.nextOfKinToUpdate.lGA = this.theNextOfKinLga.id;
    this.nextOfKinToUpdate.lGAEntity = this.theNextOfKinLga;
    this.nextOfKinToUpdate.othername =
      this.nextofkinForm.get("Othername").value;
    this.nextOfKinToUpdate.street = this.nextofkinForm.get("Street").value;
    this.nextOfKinToUpdate.relationship = this.theNxtOfKinRelationship.key;
    this.nextOfKinToUpdate.relationshipValue =
      this.theNxtOfKinRelationship.value;
    this.nextOfKinToUpdate.mobile = this.nextofkinForm.get("Mobile").value;
    this.nextofkinForm.reset();
    this.theNxtOfKinRelationship = null;
    this.theNextOfKinState = null;
    this.theNextOfKinLga = null;
    this.CloseEditingNextOfKin();

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Information Updated Successfully.",
    });
  }

  AddEducToList() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Educational Background...",
    });

    var startDate = this.educationForm.get("ProgramStartDate").value as Date;
    var endDate = this.educationForm.get("ProgramEndDate").value as Date;

    if (startDate > endDate) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Err: Start Date must not be greater than end date",
      });
      return;
    }

    this.educationalBackgroundList.push({
      educationalLevel: this.theEducationalLevel.key,
      educationalLevelValue: this.theEducationalLevel.value,
      programStartDate: this.educationForm.get("ProgramStartDate").value,
      institution: this.educationForm.get("Institution").value,
      programEndDate: this.educationForm.get("ProgramEndDate").value,
      qualificationAttained: this.educationForm.get("QualificationAttained")
        .value,
      gradeAwarded: this.educationForm.get("GradeAwarded").value,
    });
    this.educationForm.reset();
    this.theEducationalLevel = null;

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Added Successfully.",
    });
  }

  RemoveEducationalBackground(item: AddEducationalBackground) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Educational Background...",
    });

    this.educationalBackgroundList.splice(
      this.educationalBackgroundList.indexOf(item),
      1
    );

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Removed Successfully.",
    });
  }

  CloseEditingEduc() {
    this.editingEducInfo = false;
    this.educationForm.reset();
    this.theEducationalLevel = null;
    this.educInfoToUpdate = null;
  }

  EditEducInfo(item: AddEducationalBackground) {
    this.editingEducInfo = true;
    this.educationForm.patchValue({
      ProgramStartDate: item.programStartDate,
      Institution: item.institution,
      ProgramEndDate: item.programEndDate,
      QualificationAttained: item.qualificationAttained,
      GradeAwarded: item.gradeAwarded,
    });

    this.theEducationalLevel = this.allEducationalLevels.find(
      (x) => x.key == item.educationalLevel
    );
    this.educInfoToUpdate = item;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  UpdateEducInfo() {
    var startDate = this.educationForm.get("ProgramStartDate").value as Date;
    var endDate = this.educationForm.get("ProgramEndDate").value as Date;

    if (startDate > endDate) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Err: Start Date must not be greater than end date",
      });
      return;
    }

    this.educInfoToUpdate.educationalLevel = this.theEducationalLevel.key;
    this.educInfoToUpdate.educationalLevelValue =
      this.theEducationalLevel.value;
    this.educInfoToUpdate.programStartDate =
      this.educationForm.get("ProgramStartDate").value;
    this.educInfoToUpdate.institution =
      this.educationForm.get("Institution").value;
    this.educInfoToUpdate.programEndDate =
      this.educationForm.get("ProgramEndDate").value;
    this.educInfoToUpdate.qualificationAttained = this.educationForm.get(
      "QualificationAttained"
    ).value;
    this.educInfoToUpdate.gradeAwarded =
      this.educationForm.get("GradeAwarded").value;
    this.CloseEditingEduc();

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Information Updated Successfully.",
    });
  }

  LoadUpEmployerStateLGAs() {
    if (this.theEmployerState) {
      this.allEmployerLgas = this.theEmployerState.lgas;
    } else {
      this.allEmployerLgas = [];
      this.theEmployerLga = null;
    }
  }

  AddPrevEmpToList() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding Previous Employer...",
    });

    var startDate = this.prevEmployerForm.get("YearOfEmployment").value as Date;
    var endDate = this.prevEmployerForm.get("YearOfTermination").value as Date;

    if (startDate > endDate) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Err: Date of Exit must not be greater than Date of Employment",
      });
      return;
    }

    this.prevEmployerList.push({
      name: this.prevEmployerForm.get("EmployerName").value,
      role: this.prevEmployerForm.get("RoleOccupied").value,
      state: this.theEmployerState.id,
      stateName: this.theEmployerState.name,
      lGA: this.theEmployerLga.id,
      lGAName: this.theEmployerLga.name,
      street: this.prevEmployerForm.get("EmployerStreet").value,
      yearOfEmployment: this.prevEmployerForm.get("YearOfEmployment").value,
      yearOfTermination: this.prevEmployerForm.get("YearOfTermination").value,
      reasonForTermination: this.prevEmployerForm.get("ReasonForTermination")
        .value,
    });
    this.prevEmployerForm.reset();
    this.theEmployerState = null;
    this.theEmployerLga = null;
    this.allEmployerLgas = [];

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Added Successfully.",
    });
  }

  RemovePreviousEmployer(item: AddPrevEmployer) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Removing Previous Employer...",
    });

    this.prevEmployerList.splice(this.prevEmployerList.indexOf(item), 1);

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Removed Successfully.",
    });
  }

  CloseEditingPrevEmp() {
    this.editingPrevEmp = false;
    this.prevEmployerForm.reset();
    this.theEmployerLga = null;
    this.allEmployerLgas = [];
    this.theEmployerState = null;
    this.prevEmpToUpdate = null;
  }

  EditPreviousEmployer(item: AddPrevEmployer) {
    this.editingPrevEmp = true;
    this.prevEmployerForm.patchValue({
      EmployerName: item.name,
      RoleOccupied: item.role,
      YearOfEmployment: item.yearOfEmployment,
      YearOfTermination: item.yearOfTermination,
      EmployerStreet: item.street,
      ReasonForTermination: item.reasonForTermination,
    });

    this.theEmployerState = this.allStates.find((x) => x.id == item.state);
    this.allEmployerLgas = this.theEmployerState.lgas;
    this.theEmployerLga = this.allEmployerLgas.find((x) => x.id == item.lGA);
    this.prevEmpToUpdate = item;
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  UpdatePrevEmpInfo() {
    var startDate = this.prevEmployerForm.get("YearOfEmployment").value as Date;
    var endDate = this.prevEmployerForm.get("YearOfTermination").value as Date;

    if (startDate > endDate) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Err: Date of Exit must not be greater than Date of Employment",
      });
      return;
    }

    this.prevEmpToUpdate.name = this.prevEmployerForm.get("EmployerName").value;
    this.prevEmpToUpdate.role = this.prevEmployerForm.get("RoleOccupied").value;
    this.prevEmpToUpdate.state = this.theEmployerState.id;
    this.prevEmpToUpdate.stateName = this.theEmployerState.name;
    this.prevEmpToUpdate.lGA = this.theEmployerLga.id;
    this.prevEmpToUpdate.lGAName = this.theEmployerLga.name;
    this.prevEmpToUpdate.street =
      this.prevEmployerForm.get("EmployerStreet").value;
    this.prevEmpToUpdate.yearOfEmployment =
      this.prevEmployerForm.get("YearOfEmployment").value;
    this.prevEmpToUpdate.yearOfTermination =
      this.prevEmployerForm.get("YearOfTermination").value;
    this.prevEmpToUpdate.reasonForTermination = this.prevEmployerForm.get(
      "ReasonForTermination"
    ).value;

    this.CloseEditingPrevEmp();

    this.messageService.add({
      severity: "success",
      summary: "Completed",
      detail: "Information Updated Successfully.",
    });
  }

  async RegisterGuardInformation() {
    if (this.isRegistering) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Ongoing Guard Registration is still in-progress.",
      });
      return;
    }

    this.confirmationService.confirm({
      message:
        "You are about to register a new guard on the system with the provided information(s). Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail:
            "Registering New Guard Information Job Application Profile...",
        });
        this.isRegistering = true;

        const postData: StandaloneGuardRegistrationVM = {
          jobFunctionId: this.jobFunction.id,
          personalInfo: null,
          backgroundInfo: null,
          nextOfKinInfos: [],
          educationInfos: [],
          prevEmpInfos: [],
          medAcctInfo: null,
          otherInfo: null,
        };

        try {
          await this._registerPersonalInformation(postData);
        } catch (err) {
          this.isRegistering = false;
          console.log(err);
        }
      },
    });
  }

  async _registerPersonalInformation(postData: StandaloneGuardRegistrationVM) {
    postData.personalInfo = {
      firstname: this.personalForm.get("Firstname").value,
      lastname: this.personalForm.get("Lastname").value,
      email: this.personalForm.get("Email").value,
      stateId: this.theState.id,
      othernames: this.personalForm.get("Othername").value,
      lGAId: this.theLga.id,
      nickname: this.personalForm.get("Nickname").value,
      street: this.personalForm.get("Street").value,
      mobile: this.personalForm.get("Mobile").value,
      dOB: this.personalForm.get("DOB").value,
      gender: this.theApplicantGender.key,
      applicationLetterUrl: [],
    };

    this.fileStorageService.UploadFileFromDataUrl(this.uploadedPassport[0]);
    this.fileStorageService.onUploadFinished.subscribe(
      async (resp: CommonResponse<string>) => {
        if (resp.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: resp.responseMsg,
          });
          this.isRegistering = false;
        } else {
          postData.personalInfo.passportImageUrl = resp.responseData;
          if (this.uploadedApplicationLetters.length > 0) {
            this.fileStorageService.UploadMultipleFilesFromDataUrl(
              this.uploadedApplicationLetters
            );
            this.fileStorageService.onUploadFinished.subscribe(
              async (resp: CommonResponse<string[]>) => {
                if (resp.responseCode != "00") {
                  this.messageService.add({
                    severity: "error",
                    summary: "Notice",
                    detail: resp.responseMsg,
                  });
                  this.isRegistering = false;
                } else {
                  postData.personalInfo.applicationLetterUrl =
                    resp.responseData;
                  await this._registerBackgrounInformation(postData);
                }
              },
              (error) => {
                console.log("Error while uploading files " + error);
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: "ERR: Unable to upload image(s)/file(s) to storage",
                });
                this.isRegistering = false;
              }
            );
          } else {
            await this._registerBackgrounInformation(postData);
          }
        }
      },
      (error) => {
        console.log("Error while uploading file " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail: "ERR: Unable to upload image/file to storage",
        });
        this.isRegistering = false;
      }
    );
  }

  async _registerBackgrounInformation(postData: StandaloneGuardRegistrationVM) {
    postData.backgroundInfo = {
      stateOfOrigin: this.theBackgroundState.id,
      lGAOfOrigin: this.theBackgroundLga.id,
      tribe: this.backgroundForm.get("Tribe").value,
      maritalStatus: this.theMaritalStatus.key,
      marriageDate: this.backgroundForm.get("MarriageDate").value,
      marriageLocation: this.backgroundForm.get("MarriageLocation").value,
      belongsToAClub: this.isInAClubRadioButton == 1 ? true : false,
      clubName: this.backgroundForm.get("ClubName").value,
      spouseFirstname: this.backgroundForm.get("SpouseFirstname").value,
      spouseLastname: this.backgroundForm.get("SpouseLastname").value,
      spouseOccupation: this.backgroundForm.get("SpouseOccupation").value,
      fatherFullname: this.backgroundForm.get("FatherFullname").value,
      fatherAddress: this.backgroundForm.get("FatherAddress").value,
      motherFullname: this.backgroundForm.get("MotherFullname").value,
      motherAddress: this.backgroundForm.get("MotherAddress").value,
      children: [],
    };

    if (this.theMaritalStatus.key != MaritalStatusEnum.Single) {
      if (
        !postData.backgroundInfo.spouseFirstname ||
        !postData.backgroundInfo.spouseLastname ||
        !postData.backgroundInfo.spouseOccupation
      ) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Err: You must input all your spouse information to proceed.",
        });
        this.isRegistering = false;
        return;
      }
    }

    if (this.childrenAddedToList.length > 0) {
      this.childrenAddedToList.forEach((child) => {
        postData.backgroundInfo.children.push({
          firstname: child.firstname,
          lastname: child.lastname,
          gender: child.gender,
          dateOfBirth: child.dateOfBirth,
        });
      });
    }

    await this._registerNextOfKinInformation(postData);
  }

  async _registerNextOfKinInformation(postData: StandaloneGuardRegistrationVM) {
    this.nextOfKins.forEach((nextOfKin) =>
      postData.nextOfKinInfos.push({
        firstname: nextOfKin.firstname,
        lastname: nextOfKin.lastname,
        state: nextOfKin.state,
        stateEntity: nextOfKin.stateEntity,
        lGA: nextOfKin.lGA,
        lGAEntity: nextOfKin.lGAEntity,
        othername: nextOfKin.othername,
        street: nextOfKin.street,
        relationship: nextOfKin.relationship,
        relationshipValue: nextOfKin.relationshipValue,
        mobile: nextOfKin.mobile,
      })
    );

    await this._registerEducationInformation(postData);
  }

  async _registerEducationInformation(postData: StandaloneGuardRegistrationVM) {
    this.educationalBackgroundList.forEach((education) =>
      postData.educationInfos.push({
        educationalLevel: education.educationalLevel,
        educationalLevelValue: education.educationalLevelValue,
        programStartDate: education.programStartDate,
        institution: education.institution,
        programEndDate: education.programEndDate,
        qualificationAttained: education.qualificationAttained,
        gradeAwarded: education.gradeAwarded,
      })
    );

    await this._registerPrevEmployerInformation(postData);
  }

  async _registerPrevEmployerInformation(
    postData: StandaloneGuardRegistrationVM
  ) {
    this.prevEmployerList.forEach((prevEmp) =>
      postData.prevEmpInfos.push({
        name: prevEmp.name,
        role: prevEmp.role,
        state: prevEmp.state,
        stateName: prevEmp.stateName,
        lGA: prevEmp.lGA,
        lGAName: prevEmp.lGAName,
        street: prevEmp.street,
        yearOfEmployment: prevEmp.yearOfEmployment,
        yearOfTermination: prevEmp.yearOfTermination,
        reasonForTermination: prevEmp.reasonForTermination,
      })
    );

    await this._registerMedicalAccountInformation(postData);
  }

  async _registerMedicalAccountInformation(
    postData: StandaloneGuardRegistrationVM
  ) {
    // Validate Bank Account(s)
    this.paystackGatewayService
      .ResolveAccount(
        this.medAcctForm.get("PrimaryAcctNo").value,
        this.thePrimaryBank.bankCode
      )
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            this.isRegistering = false;
            return;
          } else {
            if (!data.responseData.status) {
              this.messageService.add({
                severity: "error",
                summary: "Unable to validate primary account details.",
              });
              this.isRegistering = false;
              return;
            }

            this.primaryAcctName = data.responseData.data.account_name;
            if (this.theSecondaryBank) {
              this.paystackGatewayService
                .ResolveAccount(
                  this.medAcctForm.get("SecondaryAcctNo").value,
                  this.theSecondaryBank.bankCode
                )
                .subscribe(
                  async (data2) => {
                    if (data2.responseCode != "00") {
                      this.messageService.add({
                        severity: "error",
                        summary: data2.responseMsg,
                      });
                      this.isRegistering = false;
                      return;
                    }

                    if (!data2.responseData.status) {
                      this.messageService.add({
                        severity: "error",
                        summary:
                          "Unable to validate secondary account details.",
                      });
                      this.isRegistering = false;
                      return;
                    }

                    this.secondaryAcctName =
                      data2.responseData.data.account_name;
                    postData.medAcctInfo = {
                      isDrinking:
                        this.isDrinkingRadioButton == 1 ? true : false,
                      isSmoking: this.isSmokingRadioButton == 1 ? true : false,
                      isGambling:
                        this.isGamblingRadioButton == 1 ? true : false,
                      isHypertensive:
                        this.isHypertensiveRadioButton == 1 ? true : false,
                      hasArthritis:
                        this.isArthritusRadioButton == 1 ? true : false,
                      isDiabetic:
                        this.isDiabeticRadioButton == 1 ? true : false,
                      isSufferingUnknown:
                        this.isSufferingUnknownRadioButton == 1 ? true : false,
                      unknownDiseaseDescription:
                        this.medAcctForm.get("UnknownDiseaseDesc").value,
                      primaryBank: this.thePrimaryBank.name,
                      primaryAccountBankId: this.thePrimaryBank.id,
                      primaryBankAcctNo:
                        this.medAcctForm.get("PrimaryAcctNo").value,
                      secondaryBank: this.theSecondaryBank.name,
                      secondaryAccountBankId: this.theSecondaryBank.id,
                      secondaryBankAcctNo:
                        this.medAcctForm.get("SecondaryAcctNo").value,
                      pensionPin: this.medAcctForm.get("PensionPin").value,
                      tIN: this.medAcctForm.get("TaxIdentificationNo").value,
                      bVN: this.medAcctForm.get("BVN").value,
                      pensionPayeeID: this.medAcctForm.get("PPID").value,
                      nIN: this.medAcctForm.get("NIN").value,
                      primaryAccountName: this.primaryAcctName,
                      secondaryAccountName: this.secondaryAcctName,
                    };
                    await this._registerOtherInformation(postData);
                  },
                  (error) => {
                    console.log("Error Occurred: " + error);
                    this.messageService.add({
                      severity: "error",
                      summary: "Notice",
                      detail:
                        "Unable to validate secondary bank account at the moment.. Reason: [" +
                        error
                          ? error.error.message
                          : "request failed - permission" + "]",
                    });
                  }
                );
            } else {
              postData.medAcctInfo = {
                isDrinking: this.isDrinkingRadioButton == 1 ? true : false,
                isSmoking: this.isSmokingRadioButton == 1 ? true : false,
                isGambling: this.isGamblingRadioButton == 1 ? true : false,
                isHypertensive:
                  this.isHypertensiveRadioButton == 1 ? true : false,
                hasArthritis: this.isArthritusRadioButton == 1 ? true : false,
                isDiabetic: this.isDiabeticRadioButton == 1 ? true : false,
                isSufferingUnknown:
                  this.isSufferingUnknownRadioButton == 1 ? true : false,
                unknownDiseaseDescription:
                  this.medAcctForm.get("UnknownDiseaseDesc").value,
                primaryBank: this.thePrimaryBank.name,
                primaryAccountBankId: this.thePrimaryBank.id,
                primaryBankAcctNo: this.medAcctForm.get("PrimaryAcctNo").value,
                secondaryBank: this.theSecondaryBank.name,
                secondaryAccountBankId: this.theSecondaryBank.id,
                secondaryBankAcctNo:
                  this.medAcctForm.get("SecondaryAcctNo").value,
                pensionPin: this.medAcctForm.get("PensionPin").value,
                tIN: this.medAcctForm.get("TaxIdentificationNo").value,
                bVN: this.medAcctForm.get("BVN").value,
                pensionPayeeID: this.medAcctForm.get("PPID").value,
                nIN: this.medAcctForm.get("NIN").value,
                primaryAccountName: this.primaryAcctName,
                secondaryAccountName: this.secondaryAcctName,
              };
              await this._registerOtherInformation(postData);
            }
          }
        },
        (error) => {
          console.log("Error Occurred: " + error);
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to validate primary bank account at the moment.. Reason: [" +
              error
                ? error.error.message
                : "request failed - permission" + "]",
          });
          this.isRegistering = false;
        }
      );
  }

  async _registerOtherInformation(postData: StandaloneGuardRegistrationVM) {
    postData.otherInfo = {
      isExMilitant: this.isExMilitantRadioButton == 1 ? true : false,
      hasCombatTraining: this.isCombatantRadioButton == 1 ? true : false,
      hasBeenArrested: this.isArrestedRadioButton == 1 ? true : false,
      hasBeenConvicted: this.isConvictedRadioButton == 1 ? true : false,
      hasBeenAskedToResign: this.isResignedRadioButton == 1 ? true : false,
      agreesToTerms: true,
      hasDeclared: true,
    };

    await this._submitGuardRegistrationData(postData);
  }

  async _submitGuardRegistrationData(postData: StandaloneGuardRegistrationVM) {
    this.employmentService.StandaloneGuardRegistration(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          this.isRegistering = false;
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Guard Information Registered Successfully!",
        });
        this.personalForm.reset();
        this.theApplicantGender = null;
        this.backgroundForm.reset();
        this.nextofkinForm.reset();
        this.nextOfKins = [];
        this.educationForm.reset();
        this.educationalBackgroundList = [];
        this.prevEmployerForm.reset();
        this.prevEmployerList = [];
        this.medAcctForm.reset();
        this.additionalForm.reset();
        this.activeStep = 0;
        this.passportImageSrc = null;
        this.uploadedApplicationLetters = [];
        this.theLga = null;
        this.theState = null;
        this.theEmployerLga = null;
        this.theEmployerState = null;
        this.theNextOfKinLga = null;
        this.theNextOfKinState = null;
        this.theBackgroundLga = null;
        this.theBackgroundState = null;
        this.theMaritalStatus = null;
        this.thePrimaryBank = null;
        this.theSecondaryBank = null;
        this.theGender = null;
        this.isRegistering = false;
        this.FetchAllRegisteredGuardInfos();
      },
      (error) => {
        console.log("Error Occurred: " + error);
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to submit job application at the moment.. Reason: [" + error
              ? error.error.message
              : "request failed - permission" + "]",
        });
        this.isRegistering = false;
      }
    );
  }

  ShowGuardBioRegistrationGrid() {
    if (this.theRegisteredGuard)
      this.BiometricsViewHelper(this.theRegisteredGuard);
  }

  CaptureApplicantBiometrics() {
    this.openCapturePrintsDialogue = true;
  }

  HideCapturePrintsDialog() {
    this.openCapturePrintsDialogue = false;
  }

  CaptureFingerPrint() {
    this.CallSGIFPGetData();
  }

  CallSGIFPGetData() {
    var uri = "https://localhost:8443/SGIFPCapture";

    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        let result = JSON.parse(xmlhttp.responseText);
        if (result.ErrorCode == 0) {
          /* 	Display BMP data in image tag
            BMP data is in base 64 format 
        */
          if (result != null && result.BMPBase64.length > 0) {
            (document.getElementById("FPImage1") as any).src =
              "data:image/bmp;base64," + result.BMPBase64;
            document.getElementById("FPImageTemp").innerHTML = result.BMPBase64;
            document.getElementById("FPBaseImageTemp").innerHTML =
              result.TemplateBase64;
            (document.getElementById("btnSaveFP") as any).disabled = false;
            //localStorage.setItem('src', result.BMPBase64)
          }
        } else
          alert(
            "Fingerprint Capture Error Code:  " +
              result.ErrorCode +
              ".\n Description:  " +
              JSON.stringify(result.ErrorCode) +
              "."
          );
      } else if (xmlhttp.status == 404) {
        alert(
          "Check if SGIBIOSRV is running; Status = " + xmlhttp.status + ":"
        );
      }
    };
    var params = "Timeout=" + "10000";
    params += "&Quality=" + "50";
    params +=
      "&licstr=" +
      encodeURIComponent(this.fileStorageService.GetSecugenLicenseKeyStr());
    params += "&templateFormat=" + "ISO";
    params += "&imageWSQRate=" + "0.75";
    console.log;
    xmlhttp.open("POST", uri, true);
    xmlhttp.send(params);

    xmlhttp.onerror = function () {
      alert(
        "Check if SGIBIOSRV is running; Status = " + xmlhttp.statusText + ":"
      );
    };
  }

  SaveFingerPrint() {
    if (!this.theBiometricCapture) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail: "Select Finger To Scan First!",
      });
      return;
    }

    let fp = document.getElementById("FPBaseImageTemp").innerHTML;
    const postData: AddGuardBioDataVM = {
      guardRegistrationId: this.theRegisteredGuard.id,
      biometricThump: this.theBiometricCapture.key,
      bMPBase64: fp,
    };

    this.employmentService.AddGuardBioData(postData).subscribe(
      async () => {
        this.messageService.add({
          severity: "success",
          summary: "Removed",
          detail: "Saved Successfully",
        });

        this.theBiometricCapture = null;
        (document.getElementById("FPImage1") as any).src = this.bioImgSrc;
        (document.getElementById("btnSaveFP") as any).disabled = true;
        this.theRegisteredGuard.bioDatas.push({
          bMPBase64: postData.bMPBase64,
          thumbprint: postData.biometricThump,
        });
        this.BiometricsViewHelper(this.theRegisteredGuard);
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to connect connect to FP Store server at the moment.. Reason: [" +
            JSON.stringify(error) +
            "]",
        });
      }
    );
  }

  BiometricsViewHelper(registeredGuard: GMAGuardRegistration) {
    this.applicantInViewBioData[0] = false;
    this.applicantInViewBioData[1] = false;
    this.applicantInViewBioData[2] = false;
    this.applicantInViewBioData[3] = false;
    this.applicantInViewBioData[4] = false;
    this.applicantInViewBioData[5] = false;
    this.applicantInViewBioData[6] = false;
    this.applicantInViewBioData[7] = false;
    this.applicantInViewBioData[8] = false;
    this.applicantInViewBioData[9] = false;
    this.leftBioPercentage = 0;
    this.rightBioPercentage = 0;
    registeredGuard.bioDatas.forEach((bio) => {
      if (bio.thumbprint == BiometricCaptureAction.Left_Pinky) {
        this.applicantInViewBioData[0] = true;
        this.leftBioPercentage = this.leftBioPercentage + 20;
      } else if (bio.thumbprint == BiometricCaptureAction.Left_RingFinger) {
        this.leftBioPercentage = this.leftBioPercentage + 20;
        this.applicantInViewBioData[1] = true;
      } else if (bio.thumbprint == BiometricCaptureAction.Left_MiddleFinger) {
        this.leftBioPercentage = this.leftBioPercentage + 20;
        this.applicantInViewBioData[2] = true;
      } else if (bio.thumbprint == BiometricCaptureAction.Left_IndexFinger) {
        this.leftBioPercentage = this.leftBioPercentage + 20;
        this.applicantInViewBioData[3] = true;
      } else if (bio.thumbprint == BiometricCaptureAction.Left_Thumb) {
        this.leftBioPercentage = this.leftBioPercentage + 20;
        this.applicantInViewBioData[4] = true;
      } else if (bio.thumbprint == BiometricCaptureAction.Right_Thumb) {
        this.rightBioPercentage = this.rightBioPercentage + 20;
        this.applicantInViewBioData[5] = true;
      } else if (bio.thumbprint == BiometricCaptureAction.Right_IndexFinger) {
        this.rightBioPercentage = this.rightBioPercentage + 20;
        this.applicantInViewBioData[6] = true;
      } else if (bio.thumbprint == BiometricCaptureAction.Right_MiddleFinger) {
        this.rightBioPercentage = this.rightBioPercentage + 20;
        this.applicantInViewBioData[7] = true;
      } else if (bio.thumbprint == BiometricCaptureAction.Right_RingFinger) {
        this.rightBioPercentage = this.rightBioPercentage + 20;
        this.applicantInViewBioData[8] = true;
      } else if (bio.thumbprint == BiometricCaptureAction.Right_Pinky) {
        this.rightBioPercentage = this.rightBioPercentage + 20;
        this.applicantInViewBioData[9] = true;
      }
    });

    if (this.leftBioPercentage > 100) this.leftBioPercentage = 100;
    if (this.rightBioPercentage > 100) this.rightBioPercentage = 100;
  }

  CompleteStandaloneRegistration(item: GMAJobFunction) {
    this.confirmationService.confirm({
      message: "Are you sure you want to complete this Standalone Guard Registration. This will action will automatically convert all guards registration to deployed guards, bypassing the recruitment process, and deploying all to the pool for redeployment. This is an irreversible action, Do you still wish to proceed?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Completing Standalone Guard Registration (Job Function)...",
        });

        this.employmentService.CompleteStandaloneGuardRegistrations(item.id).subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: data.responseMsg,
              });
              return;
            }
    
            this.messageService.add({
              severity: "success",
              summary: "Completed",
              detail: "Job Function Completed Successfully, All registration deployed to the pool!",
            });
            this.CloseEditingJobFunction();
            this.FetchAllJobFunctions();
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to complete job function at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
      },
    });
  }

  get StreetValue() {
    return this.personalForm.get("Street").value;
  }

  get NxtOfKinStreetValue() {
    return this.nextofkinForm.get("Street").value;
  }

  get LGAValue() {
    return this.theLga ? this.theLga.name : "";
  }

  get NxtOfKinLGAValue() {
    return this.theNextOfKinLga ? this.theNextOfKinLga.name : "";
  }

  get StateValue() {
    return this.theState ? this.theState.name : "";
  }

  get NxtOfKinStateValue() {
    return this.theNextOfKinState ? this.theNextOfKinState.name : "";
  }

  get ChildFirstnameValue() {
    return this.backgroundForm.get("ChildFirstname").value;
  }

  get ChildLastnameValue() {
    return this.backgroundForm.get("ChildLastname").value;
  }

  get ChildDOBValue() {
    return this.backgroundForm.get("ChildDOB").value;
  }

  get PrevEmpStreetValue() {
    return this.prevEmployerForm.get("EmployerStreet").value;
  }

  get PrevEmpStateValue() {
    return this.theEmployerState ? this.theEmployerState.name : "";
  }

  get PrevEmpLGAValue() {
    return this.theEmployerLga ? this.theEmployerLga.name : "";
  }
}
