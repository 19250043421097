import { CommonResponse, Contract, State } from "src/app/interfaces/home";
import { JobApplication } from "./../interfaces/premployment";
import {
  ApplicationEvaluationReq,
  ApplicationEvaluationReview,
  CalculateApplicantScore,
  CognitiveEvaluationReq,
  CreateApplicationEvaluationReq,
  CreateApplicationExamination,
  CreateCognitiveEvaluationReq,
  CreateCognitiveExamination,
  CreateNewJobPostNote,
  CreatePhysicalEvaluationReq,
  CreatePhysicalExamination,
  CreateTrainingCourse,
  CreateTrainingEvaluationReq,
  CreateTrainingExamination,
  Evaluation,
  ReviewApplicantPhysicalAssessment,
  ManageJobsPreview,
  Note,
  PhysicalEvaluationReq,
  TrainingCourse,
  TrainingEvaluationReq,
  UpdateTrainingCourse,
  ReviewCognitivePhysicalAssessment,
  ReviewApplicantGuarantorAsseessment,
  CreateBackgroundExamination,
  BackgroundEvaluationReq,
  CreateBackgroundEvaluationReq,
  ReviewApplicantBackgroundAsseessment,
  CreateTrainingSchool,
  TrainingSchool,
  TrainingSchGetAllJobPost,
  AddTrainingSchoolStudent,
  TrainingSchoolApprovalInfo,
  Student,
  TrainingEvaluationReview,
  CreateClientLocation,
  UpdateClientLocation,
  CreateGuardLocationDeployment,
  SetApplicationItems,
  LogMaterialBatchRequest,
  TrackMaterialRequestData,
  TrainingSchoolResult,
  FingerPrintScannerResp,
  AddApplicantBioData,
  ApplicantBioData,
  CreateBeatType,
  UpdateBeatType,
  CreateNewBeat,
  UpdateBeat,
  GMAZone,
  CreateZoneVM,
  UpdateZoneVM,
  CreateClusterVM,
  UpdateClusterVM,
  BeatType,
  AllocateLocationVM,
  ChecklistCategory,
  CreateCheckListCategoryVM,
  UpdateChecklistCategoryVM,
  AddNewCheckListVM,
  UpdateCheckListVM,
  GeneralPhaseChecks,
  DeployedGuard,
  GuardLocationReDeployment,
  ZonalMapViewSummary,
  ApplicantMaterialRequestBatch,
  OperativeManagements,
  DetachGuardOP,
  DistributeApplicantItemReceivingDTO,
  DistributeApplicantItems,
  OperativeManagementOPDetails,
  Beat,
  AssignGuardDeploymentsToBeat,
  Cluster,
  SubmitGeneralReportCluster,
  SubmitOperativeSpecificClusterReport,
  ClusterReportStatus,
  AddNewClusterSupervisionPolicy,
  AddLocationToPolicyCycle,
  GetActiveCycleInfoVM,
  GetBioDashSummaryVM,
  GuardDeployment,
  VerifyGeoLocation,
  VerifyCordinateAgainstLocationCordinateVM,
  GeocodingGetAddressCordinateVM,
  UpdatePersonalBioImage,
  GetApplicantTrainingResultVM,
  GetMateriaRefreshUserViewVM,
  AddRequestToMaterialRefreshBatchVM,
  GetDashboardAnalyticsVM,
  CreateGMAGuarantorVM,
  OperativeGuarantor,
  AddNewGuarantorUploadsVM,
  UpdateOperativeGuarantorVM,
  ReviewApplicantOperativeGuarantorAssessment,
  GMAFacilitator,
  GetScheduleEventUsersListTransferDTO,
  ScheduleGMATrainingEventVM,
  GMAEvent,
  CreateGMAExtFacilitatorVM,
  ApplicantMaterialRefreshBatch,
  UpdateGMAEventVM,
  CreateGMAanctionsVM,
  GMASanction,
  UnsanctionedDeployedGuardVM,
  CreateTrainingFacilityVM,
  GMATrainingFacility,
  GMAOperativeRecruitmentReport,
  GMAOperativeMaterialsAllocationReport,
  PerformBioSearchVM,
  GetApplicantEvaluationDetailsVM,
  GetReverseGeocodingLocationRespVM,
  StandaloneGuardRegistrationVM,
  GMAGuardRegistration,
  AddGuardBioDataVM,
} from "./../interfaces/employment";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  ApplicationRequirement,
  ApprovingOffice,
  CognitiveRequirement,
  CreateApplicationRequirement,
  CreateApprovingOffice,
  CreateCognitiveRequirement,
  CreatePhysicalRequirement,
  PhysicalRequirement,
  UpdateApplicationRequirement,
  UpdateCognitiveRequirement,
  UpdatePhysicalRequirement,
} from "../interfaces/employment";
import { JobPost } from "../interfaces/premployment";
import {
  Branch,
  CustomerDivision,
  GroupType,
  Location,
  ServiceCategory,
} from "../interfaces/home";
import { RegionalStore } from "../interfaces/inventory";

@Injectable({
  providedIn: "root",
})
export class GmaEmploymentService {
  baseUrl = environment.gmaBaseUrl + "Employment/";

  constructor(private http: HttpClient) {}

  CreateApprovingOffice(data: CreateApprovingOffice) {
    return this.http.post(this.baseUrl + "CreateApprovingOffice", data);
  }

  GetAllApprovingOffice(): Observable<ApprovingOffice[]> {
    return this.http.get<ApprovingOffice[]>(
      this.baseUrl + "GetAllApprovingOffice"
    );
  }

  UpdateApprovingOffice(officeId: number, data: CreateApprovingOffice) {
    return this.http.put(
      this.baseUrl + "UpdateApprovingOffice/" + officeId,
      data
    );
  }

  DeleteApprovingOffice(officeId: number) {
    return this.http.delete(this.baseUrl + "DeleteApprovingOffice/" + officeId);
  }

  MoveSequence(data: { sequence: number; goesUp: boolean }) {
    return this.http.post(this.baseUrl + "MoveSequence", data);
  }

  CreateApplicationRequirement(data: CreateApplicationRequirement) {
    return this.http.post(this.baseUrl + "CreateApplicationRequirement", data);
  }

  GetAllApplicationRequirements(): Observable<ApplicationRequirement[]> {
    return this.http.get<ApplicationRequirement[]>(
      this.baseUrl + "GetAllApplicationRequirements"
    );
  }

  UpdateApplicationRequirement(
    requirementId: number,
    data: UpdateApplicationRequirement
  ) {
    return this.http.put(
      this.baseUrl + "UpdateApplicationRequirement/" + requirementId,
      data
    );
  }

  DeleteApplicationRequirement(requirementId: number) {
    return this.http.delete(
      this.baseUrl + "DeleteApplicationRequirement/" + requirementId
    );
  }

  CreatePhysicalRequirement(data: CreatePhysicalRequirement) {
    return this.http.post(this.baseUrl + "CreatePhysicalRequirement", data);
  }

  GetAllPhysicalRequirements(): Observable<PhysicalRequirement[]> {
    return this.http.get<PhysicalRequirement[]>(
      this.baseUrl + "GetAllPhysicalRequirements"
    );
  }

  UpdatePhysicalRequirement(
    requirementId: number,
    data: UpdatePhysicalRequirement
  ) {
    return this.http.put(
      this.baseUrl + "UpdatePhysicalRequirement/" + requirementId,
      data
    );
  }

  DeletePhysicalRequirement(requirementId: number) {
    return this.http.delete(
      this.baseUrl + "DeletePhysicalRequirement/" + requirementId
    );
  }

  CreateCognitiveRequirement(data: CreateCognitiveRequirement) {
    return this.http.post(this.baseUrl + "CreateCognitiveRequirement", data);
  }

  GetAllCognitiveRequirements(): Observable<CognitiveRequirement[]> {
    return this.http.get<CognitiveRequirement[]>(
      this.baseUrl + "GetAllCognitiveRequirements"
    );
  }

  UpdateCognitiveRequirement(
    requirementId: number,
    data: UpdateCognitiveRequirement
  ) {
    return this.http.put(
      this.baseUrl + "UpdateCognitiveRequirement/" + requirementId,
      data
    );
  }

  DeleteCognitiveRequirement(requirementId: number) {
    return this.http.delete(
      this.baseUrl + "DeleteCognitiveRequirement/" + requirementId
    );
  }

  CreateTrainingCourse(data: CreateTrainingCourse) {
    return this.http.post(this.baseUrl + "CreateTrainingCourse", data);
  }

  GetAllTrainingCourses(): Observable<TrainingCourse[]> {
    return this.http.get<TrainingCourse[]>(
      this.baseUrl + "GetAllTrainingCourses"
    );
  }

  UpdateTrainingCourse(requirementId: number, data: UpdateTrainingCourse) {
    return this.http.put(
      this.baseUrl + "UpdateTrainingCourse/" + requirementId,
      data
    );
  }

  DeleteTrainingCourse(courseId: number) {
    return this.http.delete(this.baseUrl + "DeleteTrainingCourse/" + courseId);
  }

  CreateTrainingExamination(data: CreateTrainingExamination) {
    return this.http.post(this.baseUrl + "CreateTrainingExamination", data);
  }

  GetAllTrainingExamination(): Observable<Evaluation[]> {
    return this.http.get<Evaluation[]>(
      this.baseUrl + "GetAllTrainingExamination"
    );
  }

  GetAllTrainingExaminationRequirements(
    evaluationId: number
  ): Observable<TrainingEvaluationReq[]> {
    return this.http.get<TrainingEvaluationReq[]>(
      this.baseUrl + "GetAllTrainingExaminationRequirements/" + evaluationId
    );
  }

  AddTrainingExaminationRequirement(data: CreateTrainingEvaluationReq) {
    return this.http.post(
      this.baseUrl + "AddTrainingExaminationRequirement",
      data
    );
  }

  DeleteTrainingExaminationRequirement(trainingReqId: number) {
    return this.http.delete(
      this.baseUrl + "DeleteTrainingExaminationRequirement/" + trainingReqId
    );
  }

  GetAllPhysicalExaminations(): Observable<Evaluation[]> {
    return this.http.get<Evaluation[]>(
      this.baseUrl + "GetAllPhysicalExaminations"
    );
  }

  GetAllPhysicalExaminationRequirements(
    evaluationId: number
  ): Observable<PhysicalEvaluationReq[]> {
    return this.http.get<PhysicalEvaluationReq[]>(
      this.baseUrl + "GetAllPhysicalExaminationRequirements/" + evaluationId
    );
  }

  AddPhysicalExaminationRequirement(data: CreatePhysicalEvaluationReq) {
    return this.http.post(
      this.baseUrl + "AddPhysicalExaminationRequirement",
      data
    );
  }

  DeletePhysicalExaminationRequirement(physicalExamReqId: number) {
    return this.http.delete(
      this.baseUrl + "DeletePhysicalExaminationRequirement/" + physicalExamReqId
    );
  }

  CreatePhysicalExamination(data: CreatePhysicalExamination) {
    return this.http.post(this.baseUrl + "CreatePhysicalExamination", data);
  }

  GetAllApplicationExaminations(): Observable<Evaluation[]> {
    return this.http.get<Evaluation[]>(
      this.baseUrl + "GetAllApplicationExaminations"
    );
  }

  CreateApplicationExamination(data: CreateApplicationExamination) {
    return this.http.post(this.baseUrl + "CreateApplicationExamination", data);
  }

  GetAllApplicationExaminationRequirements(
    evaluationId: number
  ): Observable<ApplicationEvaluationReq[]> {
    return this.http.get<ApplicationEvaluationReq[]>(
      this.baseUrl + "GetAllApplicationExaminationRequirements/" + evaluationId
    );
  }

  AddApplicationExaminationRequirement(data: CreateApplicationEvaluationReq) {
    return this.http.post(
      this.baseUrl + "AddApplicationExaminationRequirement",
      data
    );
  }

  DeleteApplicationExaminationRequirement(appliationExamReqId: number) {
    return this.http.delete(
      this.baseUrl +
        "DeleteApplicationExaminationRequirement/" +
        appliationExamReqId
    );
  }

  CreateCognitiveExamination(data: CreateCognitiveExamination) {
    return this.http.post(this.baseUrl + "CreateCognitiveExamination", data);
  }

  GetAllCognitiveExaminations(): Observable<Evaluation[]> {
    return this.http.get<Evaluation[]>(
      this.baseUrl + "GetAllCognitiveExaminations"
    );
  }

  GetAllCognitiveExaminationRequirements(
    evaluationId: number
  ): Observable<CognitiveEvaluationReq[]> {
    return this.http.get<CognitiveEvaluationReq[]>(
      this.baseUrl + "GetAllCognitiveExaminationRequirements/" + evaluationId
    );
  }

  AddCognitiveExaminationRequirement(data: CreateCognitiveEvaluationReq) {
    return this.http.post(
      this.baseUrl + "AddCognitiveExaminationRequirement",
      data
    );
  }

  DeleteCognitiveExaminationRequirement(cognitiveExamReqId: number) {
    return this.http.delete(
      this.baseUrl +
        "DeleteCognitiveExaminationRequirement/" +
        cognitiveExamReqId
    );
  }

  GetManageJobsList(): Observable<ManageJobsPreview[]> {
    return this.http.get<ManageJobsPreview[]>(
      this.baseUrl + "GetManageJobsList"
    );
  }

  AddNewJobPostNote(data: CreateNewJobPostNote) {
    return this.http.post(this.baseUrl + "AddNewJobPostNote", data);
  }

  GetAllJobPostNotes(jobPostId: number): Observable<Note[]> {
    return this.http.get<Note[]>(
      this.baseUrl + "GetAllJobPostNotes/" + jobPostId
    );
  }

  GetAllExaminations(): Observable<Evaluation[]> {
    return this.http.get<Evaluation[]>(this.baseUrl + "GetAllExaminations");
  }

  GetCompleteApplicantDetails(
    jobApplicationId: number
  ): Observable<JobApplication> {
    return this.http.get<JobApplication>(
      this.baseUrl + "GetCompleteApplicantDetails/" + jobApplicationId
    );
  }

  ApplicantEvaluationReview(data: ApplicationEvaluationReview) {
    return this.http.post(this.baseUrl + "ApplicantEvaluationReview", data);
  }

  CalculateApplicantScore(data: CalculateApplicantScore): Observable<string> {
    return this.http.post<string>(
      this.baseUrl + "CalculateApplicantScore",
      data
    );
  }

  ReviewApplicantPhysicalAssessent(data: ReviewApplicantPhysicalAssessment) {
    return this.http.post(
      this.baseUrl + "ReviewApplicantPhysicalAssessent",
      data
    );
  }

  ReviewApplicantCognitiveAssessment(data: ReviewCognitivePhysicalAssessment) {
    return this.http.post(
      this.baseUrl + "ReviewApplicantCognitiveAssessment",
      data
    );
  }

  GetApplicantGeneralPhaseDetails(
    jobApplicationId: number
  ): Observable<JobApplication> {
    return this.http.get<JobApplication>(
      this.baseUrl + "GetApplicantGeneralPhaseDetails/" + jobApplicationId
    );
  }

  ReviewApplicantGuarantorAssessment(
    data: ReviewApplicantGuarantorAsseessment
  ) {
    return this.http.post(
      this.baseUrl + "ReviewApplicantGuarantorAssessment",
      data
    );
  }

  ReviewApplicantOperativeGuarantorAssessment(
    data: ReviewApplicantOperativeGuarantorAssessment
  ) {
    return this.http.post(
      this.baseUrl + "ReviewApplicantOperativeGuarantorAssessment",
      data
    );
  }

  CreateBackgroundExamination(data: CreateBackgroundExamination) {
    return this.http.post(this.baseUrl + "CreateBackgroundExamination", data);
  }

  GetAllBackgroundExaminations(): Observable<Evaluation[]> {
    return this.http.get<Evaluation[]>(
      this.baseUrl + "GetAllBackgroundExaminations"
    );
  }

  GetAllBackgroundExaminationRequirements(
    evaluationId: number
  ): Observable<BackgroundEvaluationReq[]> {
    return this.http.get<BackgroundEvaluationReq[]>(
      this.baseUrl + "GetAllBackgroundExaminationRequirements/" + evaluationId
    );
  }

  AddBackgroundExaminationRequirement(data: CreateBackgroundEvaluationReq) {
    return this.http.post(
      this.baseUrl + "AddBackgroundExaminationRequirement",
      data
    );
  }

  DeleteBackgroundExaminationRequirement(backgroundExamReqId: number) {
    return this.http.delete(
      this.baseUrl +
        "DeleteBackgroundExaminationRequirement/" +
        backgroundExamReqId
    );
  }

  ReviewApplicantBackgroundAssessment(
    data: ReviewApplicantBackgroundAsseessment
  ) {
    return this.http.post(
      this.baseUrl + "ReviewApplicantBackgroundAssessment",
      data
    );
  }

  CreateTrainingSchool(data: CreateTrainingSchool) {
    return this.http.post(this.baseUrl + "CreateTrainingSchool", data);
  }

  GetAllTrainingSchools(): Observable<TrainingSchool[]> {
    return this.http.get<TrainingSchool[]>(
      this.baseUrl + "GetAllTrainingSchools"
    );
  }

  GetAllTrainingSchJobPosts(): Observable<TrainingSchGetAllJobPost[]> {
    return this.http.get<TrainingSchGetAllJobPost[]>(
      this.baseUrl + "GetAllTrainingSchJobPosts"
    );
  }

  AddTrainingSchoolStudent(
    data: AddTrainingSchoolStudent
  ): Observable<Student> {
    return this.http.post<Student>(
      this.baseUrl + "AddTrainingSchoolStudent",
      data
    );
  }

  RemoveTrainingSchoolStudent(studentId: number) {
    return this.http.delete(
      this.baseUrl + "RemoveTrainingSchoolStudent/" + studentId
    );
  }

  SendTrainingSchoolForApproval(trainingSchoolId: number) {
    return this.http.get(
      this.baseUrl + "SendTrainingSchoolForApproval/" + trainingSchoolId
    );
  }

  GetAllTraingSchoolsInApprovalPhase(): Observable<TrainingSchool[]> {
    return this.http.get<TrainingSchool[]>(
      this.baseUrl + "GetAllTraingSchoolsInApprovalPhase"
    );
  }

  GetAllTrainingSchoolApprovalForUser(): Observable<TrainingSchool[]> {
    return this.http.get<TrainingSchool[]>(
      this.baseUrl + "GetAllTrainingSchoolApprovalForUser"
    );
  }

  GetTrainingSchoolApprovalInfo(): Observable<TrainingSchoolApprovalInfo> {
    return this.http.get<TrainingSchoolApprovalInfo>(
      this.baseUrl + "GetTrainingSchoolApprovalInfo"
    );
  }

  PickTrainingSchoolForApproval(trainingSchoolId: number) {
    return this.http.get(
      this.baseUrl + "PickTrainingSchoolForApproval/" + trainingSchoolId
    );
  }

  ApproveTrainingSchoolStudent(studentId: number) {
    return this.http.get(
      this.baseUrl + "ApproveTrainingSchoolStudent/" + studentId
    );
  }

  ApproveAllTrainingSchoolStudents(trainingSchoolId: number) {
    return this.http.get(
      this.baseUrl + "ApproveAllTrainingSchoolStudents/" + trainingSchoolId
    );
  }

  GetApplicantStudentInfo(jobApplicationId: number): Observable<Student> {
    return this.http.get<Student>(
      this.baseUrl + "GetApplicantStudentInfo/" + jobApplicationId
    );
  }

  TrainingEvaluationReview(data: TrainingEvaluationReview) {
    return this.http.post(this.baseUrl + "TrainingEvaluationReview", data);
  }

  GetAllClients(): Observable<CustomerDivision[]> {
    return this.http.get<CustomerDivision[]>(this.baseUrl + "GetAllClients");
  }

  CreateClientLocation(data: CreateClientLocation) {
    return this.http.post(this.baseUrl + "CreateClientLocation", data);
  }

  GetAllServiceCategories(): Observable<ServiceCategory[]> {
    return this.http.get<ServiceCategory[]>(
      this.baseUrl + "GetAllServiceCategories"
    );
  }

  DeleteClientLocation(locationId: number) {
    return this.http.delete(
      this.baseUrl + "DeleteClientLocation/" + locationId
    );
  }

  UpdateClientLocation(locationId: number, data: UpdateClientLocation) {
    return this.http.put(
      this.baseUrl + "UpdateClientLocation/" + locationId,
      data
    );
  }

  DeployGuardApplicantToLocation(data: CreateGuardLocationDeployment) {
    return this.http.post(
      this.baseUrl + "DeployGuardApplicantToLocation",
      data
    );
  }

  GetAllJobBatches(): Observable<JobPost[]> {
    return this.http.get<JobPost[]>(this.baseUrl + "GetAllJobBatches");
  }

  GetAllTrainingSchoolsForManageReq(): Observable<TrainingSchool[]> {
    return this.http.get<TrainingSchool[]>(
      this.baseUrl + "GetAllTrainingSchoolsForManageReq"
    );
  }

  SetApplicantMaterialItems(data: SetApplicationItems) {
    return this.http.post(this.baseUrl + "SetApplicantMaterialItems", data);
  }

  LogMaterialRequestBatch(data: LogMaterialBatchRequest) {
    return this.http.post(this.baseUrl + "LogMaterialRequestBatch", data);
  }

  GetAllRegionStores(): Observable<RegionalStore[]> {
    return this.http.get<RegionalStore[]>(this.baseUrl + "GetAllRegionStores");
  }

  TrackMaterialRequestBatch(
    applicantMaterialRequestId: number
  ): Observable<TrackMaterialRequestData[]> {
    return this.http.get<TrackMaterialRequestData[]>(
      this.baseUrl + "TrackMaterialRequestBatch/" + applicantMaterialRequestId
    );
  }

  TrackBatch(batchId: number): Observable<TrackMaterialRequestData[]> {
    return this.http.get<TrackMaterialRequestData[]>(
      this.baseUrl + "TrackBatch/" + batchId
    );
  }

  CheckApplicantFinalDeploymentStatus(
    jobApplicationId: number
  ): Observable<boolean> {
    return this.http.get<boolean>(
      this.baseUrl + "CheckApplicantFinalDeploymentStatus/" + jobApplicationId
    );
  }

  GetApplicantTrainingResult(
    applicantEvaluationId: number
  ): Observable<GetApplicantTrainingResultVM> {
    return this.http.get<GetApplicantTrainingResultVM>(
      this.baseUrl + "GetApplicantTrainingResult/" + applicantEvaluationId
    );
  }

  CaptureFingerPrint(): Observable<FingerPrintScannerResp> {
    var headers = new HttpHeaders()
      .set("Timeout", "10000")
      .set("Quality", "50")
      .set("licstr", "")
      .set("templateFormat", "ISO")
      .set("imageWSQRate", "0.75");

    return this.http.post<FingerPrintScannerResp>(
      "https://localhost:8443/SGIFPCapture",
      null,
      {
        headers: headers,
      }
    );
  }

  AddApplicantBioData(data: AddApplicantBioData) {
    return this.http.post(this.baseUrl + "AddApplicantBioData", data);
  }

  GetApplicantBioInfo(
    applicantEvaluationId: number
  ): Observable<ApplicantBioData[]> {
    return this.http.get<ApplicantBioData[]>(
      this.baseUrl + "GetApplicantBioInfo/" + applicantEvaluationId
    );
  }

  CreateBeatTypeSetup(data: CreateBeatType) {
    return this.http.post(this.baseUrl + "CreateBeatTypeSetup", data);
  }

  UpdateBeatType(beatTypeId: number, data: UpdateBeatType) {
    return this.http.put(this.baseUrl + "UpdateBeatType/" + beatTypeId, data);
  }

  DeleteBeatType(beatTypeId: number) {
    return this.http.delete(this.baseUrl + "DeleteBeatType/" + beatTypeId);
  }

  GetAllBeatsClients(): Observable<CustomerDivision[]> {
    return this.http.get<CustomerDivision[]>(
      this.baseUrl + "GetAllBeatsClients"
    );
  }

  CreateNewBeat(data: CreateNewBeat) {
    return this.http.post(this.baseUrl + "CreateNewBeat", data);
  }

  UpdateBeat(beatId: number, data: UpdateBeat) {
    return this.http.put(this.baseUrl + "UpdateBeat/" + beatId, data);
  }

  DeleteBeat(beatId: number) {
    return this.http.delete(this.baseUrl + "DeleteBeat/" + beatId);
  }

  GetAllZones(): Observable<GMAZone[]> {
    return this.http.get<GMAZone[]>(this.baseUrl + "GetAllZones");
  }

  CreateNewZone(data: CreateZoneVM) {
    return this.http.post(this.baseUrl + "CreateNewZone", data);
  }

  UpdateZone(zoneId: number, data: UpdateZoneVM) {
    return this.http.put(this.baseUrl + "UpdateZone/" + zoneId, data);
  }

  DeleteZone(zoneId: number) {
    return this.http.delete(this.baseUrl + "DeleteZone/" + zoneId);
  }

  GetAllZoneRegions(): Observable<Branch[]> {
    return this.http.get<Branch[]>(this.baseUrl + "GetAllZoneRegions");
  }

  GetAllClusterRegions(): Observable<Branch[]> {
    return this.http.get<Branch[]>(this.baseUrl + "GetAllClusterRegions");
  }

  CreateNewCluster(data: CreateClusterVM) {
    return this.http.post(this.baseUrl + "CreateNewCluster", data);
  }

  UpdateCluster(clusterId, data: UpdateClusterVM) {
    return this.http.put(this.baseUrl + "UpdateCluster/" + clusterId, data);
  }

  DeleteCluster(clusterId) {
    return this.http.delete(this.baseUrl + "DeleteCluster/" + clusterId);
  }

  GetAllBeatTypes(): Observable<BeatType[]> {
    return this.http.get<BeatType[]>(this.baseUrl + "GetAllBeatTypes");
  }

  GetAllClusterLocRegions(): Observable<Branch[]> {
    return this.http.get<Branch[]>(this.baseUrl + "GetAllClusterLocRegions");
  }

  GetAllUnallocatedLocations(): Observable<Location[]> {
    return this.http.get<Location[]>(
      this.baseUrl + "GetAllUnallocatedLocations"
    );
  }

  AllocateLocationsToCluster(data: AllocateLocationVM) {
    return this.http.post(this.baseUrl + "AllocateLocationsToCluster", data);
  }

  GetAllChecklistCategories(): Observable<ChecklistCategory[]> {
    return this.http.get<ChecklistCategory[]>(
      this.baseUrl + "GetAllChecklistCategories"
    );
  }

  CreateCheckListCategory(data: CreateCheckListCategoryVM) {
    return this.http.post(this.baseUrl + "CreateCheckListCategory", data);
  }

  UpdateChecklistCategory(
    checkListCategoryId: number,
    data: UpdateChecklistCategoryVM
  ) {
    return this.http.put(
      this.baseUrl + "UpdateChecklistCategory/" + checkListCategoryId,
      data
    );
  }

  DeleteChecklistCategory(checkListCategoryId: number) {
    return this.http.delete(
      this.baseUrl + "DeleteChecklistCategory/" + checkListCategoryId
    );
  }

  AddNewCheckList(data: AddNewCheckListVM) {
    return this.http.post(this.baseUrl + "AddNewCheckList", data);
  }

  UpdateCheckList(checklistId: number, data: UpdateCheckListVM) {
    return this.http.put(this.baseUrl + "UpdateCheckList/" + checklistId, data);
  }

  DeleteChecklist(checklistId: number) {
    return this.http.delete(this.baseUrl + "DeleteChecklist/" + checklistId);
  }

  GetAllGeneralPhaseChecks(
    jobPostId: number,
    gender: number,
    stateId: number
  ): Observable<GeneralPhaseChecks> {
    return this.http.get<GeneralPhaseChecks>(
      this.baseUrl +
        "GetAllGeneralPhaseChecks/" +
        jobPostId +
        "/" +
        gender +
        "/" +
        stateId
    );
  }

  DeAllocateClusterLocation(locationId: number) {
    return this.http.get(
      this.baseUrl + "DeAllocateClusterLocation/" + locationId
    );
  }

  GetAllGuardOperatives(): Observable<DeployedGuard[]> {
    return this.http.get<DeployedGuard[]>(
      this.baseUrl + "GetAllGuardOperatives"
    );
  }

  GetAllGuardOperativesAlone(): Observable<DeployedGuard[]> {
    return this.http.get<DeployedGuard[]>(
      this.baseUrl + "GetAllGuardOperativesAlone"
    );
  }

  GetGuardOperativeDetails(deployedGuardId: number): Observable<DeployedGuard> {
    return this.http.get<DeployedGuard>(
      this.baseUrl + "GetGuardOperativeDetails/" + deployedGuardId
    );
  }

  GetAllGuardOperativesDeployments(data: {
    deployedGuardIds: number[];
  }): Observable<GuardDeployment[]> {
    return this.http.post<GuardDeployment[]>(
      this.baseUrl + "GetAllGuardOperativesDeployments",
      data
    );
  }

  GetAllClientsAlone(): Observable<CustomerDivision[]> {
    return this.http.get<CustomerDivision[]>(
      this.baseUrl + "GetAllClientsAlone"
    );
  }

  GetAllClientTypesAlone(): Observable<GroupType[]> {
    return this.http.get<GroupType[]>(this.baseUrl + "GetAllClientTypesAlone");
  }

  GetAllClientLocationsWithServices(
    customerDivisionId: number
  ): Observable<Location[]> {
    return this.http.get<Location[]>(
      this.baseUrl + "GetAllClientLocationsWithServices/" + customerDivisionId
    );
  }

  GetAllGroupTypeClients(groupTypeId: number): Observable<CustomerDivision[]> {
    return this.http.get<CustomerDivision[]>(
      this.baseUrl + "GetAllGroupTypeClients/" + groupTypeId
    );
  }

  GetAllClientsLocations(clientId: number): Observable<Location[]> {
    return this.http.get<Location[]>(
      this.baseUrl + "GetAllClientsLocations/" + clientId
    );
  }

  GetAllClientLocationContractServices(
    customerDivisionId: number
  ): Observable<Contract[]> {
    return this.http.get<Contract[]>(
      this.baseUrl +
        "GetAllClientLocationContractServices/" +
        customerDivisionId
    );
  }

  RedeployDeployedGuard(data: GuardLocationReDeployment) {
    return this.http.post(this.baseUrl + "RedeployDeployedGuard", data);
  }

  GetAllZoneMapViewSummary(): Observable<ZonalMapViewSummary> {
    return this.http.get<ZonalMapViewSummary>(
      this.baseUrl + "GetAllZoneMapViewSummary"
    );
  }

  GetAllLocationOperatives(locationId: number): Observable<DeployedGuard[]> {
    return this.http.get<DeployedGuard[]>(
      this.baseUrl + "GetAllLocationOperatives/" + locationId
    );
  }

  GetAllMaterialRequestBatches(): Observable<ApplicantMaterialRequestBatch[]> {
    return this.http.get<ApplicantMaterialRequestBatch[]>(
      this.baseUrl + "GetAllMaterialRequestBatches"
    );
  }

  GetAllOperativesForMG(): Observable<OperativeManagements> {
    return this.http.get<OperativeManagements>(
      this.baseUrl + "GetAllOperativesForMG"
    );
  }

  GetAllOperativesDeploymentsForMG(
    deployedGuardId: number
  ): Observable<GuardDeployment[]> {
    return this.http.get<GuardDeployment[]>(
      this.baseUrl + "GetAllOperativesDeploymentsForMG/" + deployedGuardId
    );
  }

  GetAllClustersWithZoneMG(): Observable<Cluster[]> {
    return this.http.get<Cluster[]>(this.baseUrl + "GetAllClustersWithZoneMG");
  }

  DetachGuardOperatives(data: DetachGuardOP) {
    return this.http.post(this.baseUrl + "DetachGuardOperatives", data);
  }

  GetAllZoneMapViewRegions(): Observable<Branch[]> {
    return this.http.get<Branch[]>(this.baseUrl + "GetAllZoneMapViewRegions");
  }

  DistributeAllApplicantRequestItems(applicantMaterialRequestId: number) {
    return this.http.get(
      this.baseUrl +
        "DistributeAllApplicantRequestItems/" +
        applicantMaterialRequestId
    );
  }

  // DistributeApplicantRequestItems(data: DistributeApplicantItemReceivingDTO) {
  //   return this.http.post(
  //     this.baseUrl + "DistributeApplicantRequestItems",
  //     data
  //   );
  // }

  DistributeApplicantRequestItems(data: DistributeApplicantItems) {
    return this.http.post(
      this.baseUrl + "DistributeApplicantRequestItems",
      data
    );
  }

  GetOperativeDetailsMG(
    jobApplicationId: number
  ): Observable<OperativeManagementOPDetails> {
    return this.http.get<OperativeManagementOPDetails>(
      this.baseUrl + "GetOperativeDetailsMG/" + jobApplicationId
    );
  }

  GetAllClientsBeatAllocate(): Observable<CustomerDivision[]> {
    return this.http.get<CustomerDivision[]>(
      this.baseUrl + "GetAllClientsBeatAllocate"
    );
  }

  GetAllClientsBeatAllocateLocDits(
    locationId: number
  ): Observable<GuardDeployment[]> {
    return this.http.get<GuardDeployment[]>(
      this.baseUrl + "GetAllClientsBeatAllocateLocDits/" + locationId
    );
  }

  GetAllLocationBeats(locationId: number): Observable<Beat[]> {
    return this.http.get<Beat[]>(
      this.baseUrl + "GetAllLocationBeats/" + locationId
    );
  }

  AssignGuardDeploymentsToBeat(data: AssignGuardDeploymentsToBeat) {
    return this.http.post(this.baseUrl + "AssignGuardDeploymentsToBeat", data);
  }

  GetAllUserClusters(): Observable<Cluster[]> {
    return this.http.get<Cluster[]>(this.baseUrl + "GetAllUserClusters");
  }

  GetLocationSupervisoryInfo(locationId: number): Observable<Location> {
    return this.http.get<Location>(
      this.baseUrl + "GetLocationSupervisoryInfo/" + locationId
    );
  }

  SubmitGeneralReportCluster(data: SubmitGeneralReportCluster) {
    return this.http.post(this.baseUrl + "SubmitGeneralReportCluster", data);
  }

  SubmitOperativeSpecificReportCluster(
    data: SubmitOperativeSpecificClusterReport
  ) {
    return this.http.post(
      this.baseUrl + "SubmitOperativeSpecificReportCluster",
      data
    );
  }

  CheckLocationReportSubmissionStatus(
    locationId: number
  ): Observable<ClusterReportStatus> {
    return this.http.get<ClusterReportStatus>(
      this.baseUrl + "CheckLocationReportSubmissionStatus/" + locationId
    );
  }

  GetAllUserZones(): Observable<GMAZone[]> {
    return this.http.get<GMAZone[]>(this.baseUrl + "GetAllUserZones");
  }

  AddNewClusterSupervisionPolicy(data: AddNewClusterSupervisionPolicy) {
    return this.http.post(
      this.baseUrl + "AddNewClusterSupervisionPolicy",
      data
    );
  }

  ConfirmMaterialRequestItemDistribution(materialConfirmationId: number) {
    return this.http.get(
      this.baseUrl +
        "ConfirmMaterialRequestItemDistribution/" +
        materialConfirmationId
    );
  }

  DeactivateClusterPolicy(clusterPolicyId: number) {
    return this.http.get(
      this.baseUrl + "DeactivateClusterPolicy/" + clusterPolicyId
    );
  }

  AddLocationToPolicyCycle(data: AddLocationToPolicyCycle) {
    return this.http.post(this.baseUrl + "AddLocationToPolicyCycle", data);
  }

  GetActiveCycleInfo(cycleId: number): Observable<GetActiveCycleInfoVM> {
    return this.http.get<GetActiveCycleInfoVM>(
      this.baseUrl + "GetActiveCycleInfo/" + cycleId
    );
  }

  GetAllOperativesBioList(): Observable<DeployedGuard[]> {
    return this.http.get<DeployedGuard[]>(
      this.baseUrl + "GetAllOperativesBioList"
    );
  }

  GetOperativeBioInfo(jobApplicationId: number): Observable<JobApplication> {
    return this.http.get<JobApplication>(
      this.baseUrl + "GetOperativeBioInfo/" + jobApplicationId
    );
  }

  GetBioDashSummary(): Observable<GetBioDashSummaryVM> {
    return this.http.get<GetBioDashSummaryVM>(
      this.baseUrl + "GetBioDashSummary"
    );
  }

  GetDeploymentInfo(deployedGuardId: number): Observable<GuardDeployment> {
    return this.http.get<GuardDeployment>(
      this.baseUrl + "GetDeploymentInfo/" + deployedGuardId
    );
  }

  GetAllChecklistsForReporting(): Observable<ChecklistCategory[]> {
    return this.http.get<ChecklistCategory[]>(
      this.baseUrl + "GetAllChecklistsForReporting"
    );
  }

  VerifyCordinateAgainstLocationCordinate(
    data: VerifyCordinateAgainstLocationCordinateVM
  ): Observable<VerifyGeoLocation> {
    return this.http.post<VerifyGeoLocation>(
      this.baseUrl + "VerifyCordinateAgainstLocationCordinate",
      data
    );
  }

  GetAllClientsForClientLoc(): Observable<CustomerDivision[]> {
    return this.http.get<CustomerDivision[]>(
      this.baseUrl + "GetAllClientsForClientLoc"
    );
  }

  GetClientRemDetailsForClientLoc(
    clientId: number
  ): Observable<CustomerDivision> {
    return this.http.get<CustomerDivision>(
      this.baseUrl + "GetClientRemDetailsForClientLoc/" + clientId
    );
  }

  GetAddressGeocodingLocation(
    address: string
  ): Observable<GeocodingGetAddressCordinateVM> {
    return this.http.get<GeocodingGetAddressCordinateVM>(
      this.baseUrl + "GetAddressGeocodingLocation/" + address
    );
  }

  GetCurrentGeolocationCordinate(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "GetCurrentGeolocationCordinate");
  }

  GetReverseGeocodingLocation(
    latitude: number,
    longititude: number
  ): Observable<GetReverseGeocodingLocationRespVM> {
    return this.http.get<GetReverseGeocodingLocationRespVM>(
      this.baseUrl +
        "GetReverseGeocodingLocation/" +
        latitude +
        "/" +
        longititude
    );
  }

  UpdateApplicantPicture(data: UpdatePersonalBioImage) {
    return this.http.post(this.baseUrl + "UpdateApplicantPicture", data);
  }

  GetAllStates(): Observable<State[]> {
    return this.http.get<State[]>(this.baseUrl + "GetAllStates");
  }

  GetAllOperativesInPool(): Observable<GuardDeployment[]> {
    return this.http.get<GuardDeployment[]>(
      this.baseUrl + "GetAllOperativesInPool"
    );
  }

  GetMateriaRefreshUserView(): Observable<GetMateriaRefreshUserViewVM> {
    return this.http.get<GetMateriaRefreshUserViewVM>(
      this.baseUrl + "GetMateriaRefreshUserView"
    );
  }

  GetMateriaRefreshUserViewOngoingBatch(): Observable<
    ApplicantMaterialRefreshBatch[]
  > {
    return this.http.get<ApplicantMaterialRefreshBatch[]>(
      this.baseUrl + "GetMateriaRefreshUserViewOngoingBatch"
    );
  }

  AddRequestToMaterialRefreshBatch(data: AddRequestToMaterialRefreshBatchVM) {
    return this.http.post(
      this.baseUrl + "AddRequestToMaterialRefreshBatch",
      data
    );
  }

  RemoveApplicantMaterialRefreshBatch(applicantMaterialRefreshBatchId: number) {
    return this.http.delete(
      this.baseUrl +
        "RemoveApplicantMaterialRefreshBatch/" +
        applicantMaterialRefreshBatchId
    );
  }

  RaiseMaterialRefreshMaterialRequest(regionalStoreId: number) {
    return this.http.get(
      this.baseUrl + "RaiseMaterialRefreshMaterialRequest/" + regionalStoreId
    );
  }

  RaiseMaterialReplacementMaterialRequest(regionalStoreId: number) {
    return this.http.get(
      this.baseUrl +
        "RaiseMaterialReplacementMaterialRequest/" +
        regionalStoreId
    );
  }

  GetDashboardAnalytics(): Observable<GetDashboardAnalyticsVM> {
    return this.http.get<GetDashboardAnalyticsVM>(
      this.baseUrl + "GetDashboardAnalytics"
    );
  }

  CreateGMAGuarantor(data: CreateGMAGuarantorVM) {
    return this.http.post(this.baseUrl + "CreateGMAGuarantor", data);
  }

  GetAllOperativeGuarantors(): Observable<OperativeGuarantor[]> {
    return this.http.get<OperativeGuarantor[]>(
      this.baseUrl + "GetAllOperativeGuarantors"
    );
  }

  DeleteOperativeGuarantor(guarantorId: number) {
    return this.http.delete(
      this.baseUrl + "DeleteOperativeGuarantor/" + guarantorId
    );
  }

  AddNewGuarantorUploads(data: AddNewGuarantorUploadsVM): Observable<string> {
    return this.http.post<string>(
      this.baseUrl + "AddNewGuarantorUploads",
      data
    );
  }

  UpdateOperativeGuarantor(
    guarantorId: number,
    data: UpdateOperativeGuarantorVM
  ) {
    return this.http.put(
      this.baseUrl + "UpdateOperativeGuarantor/" + guarantorId,
      data
    );
  }

  GetMateriaReplacementUserView(): Observable<GetMateriaRefreshUserViewVM> {
    return this.http.get<GetMateriaRefreshUserViewVM>(
      this.baseUrl + "GetMateriaReplacementUserView"
    );
  }

  GetMateriaReplacementUserViewOngoingBatch(): Observable<
    ApplicantMaterialRefreshBatch[]
  > {
    return this.http.get<ApplicantMaterialRefreshBatch[]>(
      this.baseUrl + "GetMateriaReplacementUserViewOngoingBatch"
    );
  }

  CreateGMAFacilitator(userprofileId: number) {
    return this.http.get(
      this.baseUrl + "CreateGMAFacilitator/" + userprofileId
    );
  }

  GetAllGMAFacilitators(): Observable<GMAFacilitator[]> {
    return this.http.get<GMAFacilitator[]>(
      this.baseUrl + "GetAllGMAFacilitators"
    );
  }

  DeleteGMAFacilitator(facilitatorId: number) {
    return this.http.delete(
      this.baseUrl + "DeleteGMAFacilitator/" + facilitatorId
    );
  }

  GetScheduleEventUsersList(): Observable<GetScheduleEventUsersListTransferDTO> {
    return this.http.get<GetScheduleEventUsersListTransferDTO>(
      this.baseUrl + "GetScheduleEventUsersList"
    );
  }

  ScheduleGMATrainingEvent(data: ScheduleGMATrainingEventVM) {
    return this.http.post(this.baseUrl + "ScheduleGMATrainingEvent", data);
  }

  GetAllScheduledTrainingEvents(): Observable<GMAEvent[]> {
    return this.http.get<GMAEvent[]>(
      this.baseUrl + "GetAllScheduledTrainingEvents"
    );
  }

  UpdateGMAEvent(eventId: number, data: UpdateGMAEventVM) {
    return this.http.put(this.baseUrl + "UpdateGMAEvent/" + eventId, data);
  }

  CreateGMAExtFacilitator(data: CreateGMAExtFacilitatorVM) {
    return this.http.post(this.baseUrl + "CreateGMAExtFacilitator", data);
  }

  CreateGMSanctions(data: CreateGMAanctionsVM) {
    return this.http.post(this.baseUrl + "CreateGMSanctions", data);
  }

  GetAllGMASanctions(): Observable<GMASanction[]> {
    return this.http.get<GMASanction[]>(this.baseUrl + "GetAllGMASanctions");
  }

  RemoveGMASanction(sanctionId: number) {
    return this.http.delete(this.baseUrl + "RemoveGMASanction/" + sanctionId);
  }

  GettAllUnsanctionOperatives(): Observable<UnsanctionedDeployedGuardVM[]> {
    return this.http.get<UnsanctionedDeployedGuardVM[]>(
      this.baseUrl + "GettAllUnsanctionOperatives"
    );
  }

  GetAllApplicantsListing(): Observable<JobApplication[]> {
    return this.http.get<JobApplication[]>(
      this.baseUrl + "GetAllApplicantsListing"
    );
  }

  CreateTrainingFacility(data: CreateTrainingFacilityVM) {
    return this.http.post(this.baseUrl + "CreateTrainingFacility", data);
  }

  GetAllTrainingFacilities(): Observable<GMATrainingFacility[]> {
    return this.http.get<GMATrainingFacility[]>(
      this.baseUrl + "GetAllTrainingFacilities"
    );
  }

  RemoveTrainingFacility(facilityId: number) {
    return this.http.delete(
      this.baseUrl + "RemoveTrainingFacility/" + facilityId
    );
  }

  GetAllTrainingFacilitiesOnly(): Observable<GMATrainingFacility[]> {
    return this.http.get<GMATrainingFacility[]>(
      this.baseUrl + "GetAllTrainingFacilitiesOnly"
    );
  }

  GetApplicantRecruitmentReport(
    jobApplicationId: number
  ): Observable<GMAOperativeRecruitmentReport> {
    return this.http.get<GMAOperativeRecruitmentReport>(
      this.baseUrl + "GetApplicantRecruitmentReport/" + jobApplicationId
    );
  }

  GetApplicantMaterialsAllocation(
    jobApplicationId: number
  ): Observable<GMAOperativeMaterialsAllocationReport[]> {
    return this.http.get<GMAOperativeMaterialsAllocationReport[]>(
      this.baseUrl + "GetApplicantMaterialsAllocation/" + jobApplicationId
    );
  }

  GetAllBiometricData(): Observable<ApplicantBioData[]> {
    return this.http.get<ApplicantBioData[]>(
      this.baseUrl + "GetAllBiometricData"
    );
  }

  PerformBioSearch(data: PerformBioSearchVM) {
    return this.http.post(this.baseUrl + "PerformBioSearch", data);
  }

  GetApplicantEvaluationDetails(
    applicantEvaluationId: number
  ): Observable<GetApplicantEvaluationDetailsVM> {
    return this.http.get<GetApplicantEvaluationDetailsVM>(
      this.baseUrl + "GetApplicantEvaluationDetails/" + applicantEvaluationId
    );
  }

  GetAllOperativesDataOnly(): Observable<DeployedGuard[]> {
    return this.http.get<DeployedGuard[]>(
      this.baseUrl + "GetAllOperativesDataOnly"
    );
  }

  StandaloneGuardRegistration(
    data: StandaloneGuardRegistrationVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "StandaloneGuardRegistration",
      data
    );
  }

  GetAllRegisteredGuardInformations(): Observable<
    CommonResponse<GMAGuardRegistration[]>
  > {
    return this.http.get<CommonResponse<GMAGuardRegistration[]>>(
      this.baseUrl + "GetAllRegisteredGuardInformations"
    );
  }

  AddGuardBioData(
    data: AddGuardBioDataVM
  ): Observable<CommonResponse<boolean>> {
    return this.http.post<CommonResponse<boolean>>(
      this.baseUrl + "AddGuardBioData",
      data
    );
  }

  CompleteStandaloneGuardRegistrations(
    jobFunctionId: number
  ): Observable<CommonResponse<boolean>> {
    return this.http.get<CommonResponse<boolean>>(
      this.baseUrl + "CompleteStandaloneGuardRegistrations/" + jobFunctionId
    );
  }
}
